import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { ApiService } from './api.service';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing/app-routing.module';

import { AppComponent } from './app.component';
import { PersonalizarComponent } from './personalizar/personalizar.component';
import { ArteClienteComponent } from './arte-cliente/arte-cliente.component';
import { BrindesComponent } from './brindes/brindes.component';
import { BuscaComponent } from './busca/busca.component';
import { CategoriaComponent } from './categoria/categoria.component';
import { InicioComponent } from './inicio/inicio.component';
import { EmpresaComponent } from './empresa/empresa.component';
import { RodapeComponent } from './rodape/rodape.component';
import { TopoComponent } from './topo/topo.component';

@NgModule({
  declarations: [
    AppComponent,
    PersonalizarComponent,
    ArteClienteComponent,
    BrindesComponent,
    BuscaComponent,
    CategoriaComponent,
    InicioComponent,
    EmpresaComponent,
    RodapeComponent,
    TopoComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule
  ],
  providers: [ApiService, Title],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
