import { Component, OnInit } from "@angular/core";
import { ApiService } from "../api.service";
import { Router } from "@angular/router";
import { Title } from "@angular/platform-browser";
import Typed from "typed.js";

import * as THREE from "three";
import GLTFLoader from "three-gltf-loader";
import { OrbitControls } from "three-orbitcontrols-ts";

@Component({
  selector: "app-inicio",
  templateUrl: "./inicio.component.html",
  styleUrls: ["./inicio.component.css"],
})
export class InicioComponent implements OnInit {
  carregando = true;
  renderer = new THREE.WebGLRenderer({ alpha: false });

  scene = null;
  camera = null;
  mesh = null;
  loader = null;
  object = null;
  ambientLight = null;
  directionalLight = null;
  controls = null;

  container = null;
  w = null;
  h = null;

  caneca = "../assets/caneca.gltf";

  categorias = [{}];
  brindes = [{}];
  selectedMovie;

  constructor(
    private api: ApiService,
    private router: Router,
    private title: Title
  ) {
    this.scene = new THREE.Scene();

    this.getCategorias();
    this.getBrindes();

    this.ambientLight = new THREE.AmbientLight(0xcccccc);
    this.scene.add(this.ambientLight);

    this.directionalLight = new THREE.DirectionalLight(0xcccccc);
    this.directionalLight.position.set(1, 1, 1).normalize();
    this.scene.add(this.directionalLight);

    this.add_gltf();
  }

  getCategorias = () => {
    this.api.getAllCategorias().subscribe(
      (data) => {
        this.categorias = data;
      },
      (error) => {
        console.log(error);
        this.carregando = false;
      }
    );
  };

  getBrindes = () => {
    this.api.getAllBrindes().subscribe(
      (data) => {
        this.brindes = data;
        this.carregando = false;
      },
      (error) => {
        console.log(error);
        this.carregando = false;
      }
    );
  };

  ngOnInit() {
    this.title.setTitle(
      "The Brindes - Brindes Personalizados em Teresina e todo Brasil!"
    );
    const options = {
      stringsElement: "#typed-strings",
      strings: [
        "Agora você pode visualizar alguns de nossos modelos de canecas diretamente de nosso site.",
        "Basta clicar no botão abaixo e escolher um modelo.",
      ],
      typeSpeed: 40,
      backSpeed: 200,
      backDelay: 1300,
      smartBackspace: true,
      fadeOut: true,
      showCursor: false,
      startDelay: 1000,
      loop: true,
    };
    // const typed = new Typed('.typing-element', options);
  }

  onClickSubmitSearch(busca) {
    this.router.navigateByUrl("/busca/" + busca.campo_busca);
  }

  ngAfterViewInit() {
    this.container = document.getElementById("canvas");
    this.w = this.container.offsetWidth;
    this.h = this.container.offsetHeight;

    this.camera = new THREE.PerspectiveCamera(25, this.w / this.h, 1, 20000);
    this.camera.position.set(1, 1, 20);

    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.update();
    this.controls.minDistance = 10;
    this.controls.maxDistance = 50;

    this.renderer.setClearColor(0xffffff);
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(this.w, this.h);
    this.container.appendChild(this.renderer.domElement);
    this.animate();
  }
  animate() {
    window.requestAnimationFrame(() => this.animate());
    this.object.rotation.y += 0.01;
    this.controls.update();
    this.controls.minDistance = 10;
    this.controls.maxDistance = 50;
    this.renderer.render(this.scene, this.camera);
  }

  add_gltf() {
    this.loader = new GLTFLoader();
    this.loader.load(this.caneca, (gltf) => {
      this.object = gltf.scene;
      this.object.scale.set(2.7, 2.7, 2.7);
      this.object.position.x = 0; //Position (x = right+ left-)
      this.object.position.y = 0; //Position (y = up+, down-)
      this.object.position.z = 0; //Position (z = front +, back-)

      this.scene.add(this.object);
    });
  }
}
